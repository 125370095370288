import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FCLink from '../fc_link/fc_link';
import get from 'lodash/get';
import { slugify } from '../../../utils/string_util';

/* eslint-disable */
class SectorsList extends Component {
  getSector(sector) {
    const { lookupField } = this.props;
    return get(sector, `customFields.most_innovative_companies.${lookupField}`, 'TODO: MISSING DATA')
  }

  getStyles(sector) {
    const imgName = slugify(this.getSector(sector));
    const { lookupField } = this.props;
    let url = '';

    if (lookupField === 'region') {
      url = "https://assets.fastcompany.com/image/upload/v1582829450/MIC/2020/sectors/region.svg";
    } else {
      url = `https://assets.fastcompany.com/image/upload/v1582829457/MIC/2020/sectors/${imgName}.svg`
    }

    return {
      backgroundImage: `url(${url})`
    }
  }

  render() {
    const { title, sectors = [], config, forwardRef } = this.props;

    return (
      <div className="sectors-list" ref={forwardRef}>
        <header className="sectors-list__header">
          <h3>{title}</h3>
        </header>
        <div className="sectors-list__container">
          {sectors.map((sector) => (
            <div className="sectors-list__sector" style={this.getStyles(sector)}>
              <FCLink
                title={this.getSector(sector)}
                to={sector.link}
                config={config}
              >
                {this.getSector(sector)}
              </FCLink>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

SectorsList.propTypes = {
  title: PropTypes.string.isRequired,
  sectors: PropTypes.shape([]).isRequired,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  companies: PropTypes.shape([]).isRequired,
  config: PropTypes.shape({}).isRequired,
  lookupField: PropTypes.string.isRequired
};

export default SectorsList;
